<template>
  <VueSlickCarousel
    class="carousel--wrapper carousel-info--window"
    v-bind="settings"
    @beforeChange="beforeChange"
    @afterChange="afterChange"
  >
    <div v-for="content in contents" :key="content.uuid" class="carousel--slide">
      <a
        :href="content.links.detail"
        target="_blank"
        @click="clickHandler"
        class="carousel--slide-wrapper"
      >
        <div class="wrapper--img" v-if="content">
          <img :src="content.primary_photo.url" :alt="altString(content)" />
        </div>
        <div class="card-body" v-if="content">
          <div>
            <div class="wrapper--title">
              <p class="h6" v-clampy="6">{{ content.listing_title }}</p>
            </div>
            <div class="wrapper">
              <p class="color--primary m-0">
                <strong>{{ content.property.property_type_name }}</strong>
              </p>
            </div>
            <div v-if="content.view_to_offer" class="wrapper--price">
              <p class="h6 mb-0">
                <strong>View To Offer</strong>
              </p>
            </div>
            <div
              class="wrapper--price"
              v-if="content.rent_prices && content.rent_prices.length > 0 && !content.view_to_offer"
            >
              <p class="h6 mb-0" v-for="price in content.rent_prices" :key="price.id">
                <strong>{{ $n(price.price, 'currency', 'en-SG') }}</strong> /
                {{ price.periode | lowerCase }}
              </p>
            </div>
            <div class="wrapper--price" v-else-if="content.sell_price && !content.view_to_offer">
              <p class="h6 mb-0">
                <strong>{{ $n(content.sell_price, 'currency', 'en-SG') }}</strong>
              </p>
            </div>
            <div class="wrapper" v-if="content.type === 'R'">
              <p class="h6 color--primary">{{ $t('general.rent') }} {{ content.rent_type }}</p>
            </div>
          </div>
        </div>
      </a>
    </div>
    <template #prevArrow>
      <div class="custom-prev--arrow"></div>
    </template>
    <template #nextArrow>
      <div class="custom-next--arrow"></div>
    </template>
  </VueSlickCarousel>
</template>

<script>
export default {
  props: {
    contents: {
      default: [],
      require: true,
    },
  },
  data: () => ({
    dragging: false,
    settings: {
      dots: false,
      edgeFriction: 0.35,
      infinite: true,
      autoplaySpeed: 1500,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: 'progressive',
      draggable: false,
    },
  }),
  methods: {
    beforeChange() {
      this.dragging = true;
    },
    afterChange() {
      this.dragging = false;
    },
    clickHandler(e) {
      if (this.dragging) {
        e.preventDefault();
      }
    },
    altString(listing) {
      if (!listing) return '';
      return `Disewakan ${listing.listing_title_mobile[0]}${listing.listing_title_mobile[1]}`;
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.wrapper--price {
  word-break: break-word;
}
.small--info-window {
  .carousel--slide-wrapper {
    display: flex;
    align-items: center;
  }
  .wrapper--img {
    img {
      width: 80px;
      height: 60px;
      object-fit: contain;
    }
  }
  .card-body {
    width: calc(100% - 80px);
    padding: 0.5rem 1rem;

    .wrapper--title {
      display: none;
    }

    .wrapper {
      p {
        font-size: $font-xs;
      }
    }

    .wrapper--price {
      p {
        font-size: $font-xs;
      }
    }
  }
}
</style>
