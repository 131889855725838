<template>
  <div @mouseleave="$emit('mouseleave')" @mouseenter="$emit('mouseenter')">
    <slick-info-window
      :contents="listings"
      v-if="listings && listings.length > 0"
    ></slick-info-window>
  </div>
</template>

<script>
import SlickInfoWindow from './slick-info-window';

export default {
  components: { SlickInfoWindow },
  props: {
    listings: {
      type: Array,
      required: true,
    },
  },
};
</script>
